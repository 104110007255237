module.exports = {
  common: {
    browser: 'HAH Browser',
    address: 'Address',
    block: 'Block',
    tx: 'Transaction',
    placeholder: 'Search address / block / transaction',
    search: 'Search',
    more: 'More',
    newBlock: 'Latest Block'
  },
  eFooterBar: {
    link: 'We are social',
    fork: 'Fork me on GitHub',
    power: 'Powered by',
    webService: 'Web Services',
  },
  eTopBar: {
    home: 'Home',
    rich: 'Rich List',
    branch: 'Branch',
    alt: 'The browser can query all the information on the chain, including the latest burst block, the latest transaction information, transactions in the block, etc',
  },
  Address: {
    addressInfo: 'Address Information',
    address: 'Address',
    balance: 'Balance',
    totalRevenue: 'Total Revenue',
    totalTransferOut: 'Total Transfer Out',
    rank: 'Ranking',
    transactions: 'Transactions',
    hash: 'Hash',
    time: 'Time',
    block: 'Block',
    txFee: 'Force Fee',
    locked: 'Locked',
    hidden: 'Collapse',
    timeVault: 'Negative interest rate time cumulative value',
    preVaultTime: 'Last Trade'
  },
  Block: {
    blockInfo: 'Block Information',
    returnHome: 'Return Block Home',
    blockHASH: 'Block HASH',
    time: 'Time',
    transactions: 'Number of transactions ',
    previousBlock: 'Previous Block',
    address: 'Address',
    reward: 'Block Reward',
    tx: 'Transaction',
    block: 'block',
    txFee: 'Force Fee',
    hash: 'Hash',
    blockStatistics: 'Block Statistics',
    blockTrade: '{count} transacions in this block',
    blockNumber: 'Block Number',
    blockSlot: 'Block Time Slot'
  },
  BlockList: {
    h1: 'Hah browser queries the latest burst block on the chain, burst time, block size and broadcaster.',
    h2: 'Hah browser displays the latest block explosion, including the latest block explosion time, TPS, average block out time, block height, broadcaster, size, reward, number of transactions and total transaction amount. ',
    newBlock: 'New Block',
    height: 'Height',
    address: 'Address',
    reward: 'Reward',
    amount: 'Amount',
    previousBlock: 'Previous Block',
    time: 'Time',
  },
  dpos: {
    h1: 'dpos pow',
    h2: 'The browser displays dpos, including address, voting and type. ',
    dpos: 'dpos',
    serialNumber: 'Serial',
    address: 'Address',
    vote: 'Vote',
    name: 'Name',
  },
  dposDetail: {
    h1: 'dpos pow detail',
    h2: 'Display dpos details in browser',
    dposDetail: 'dpos detail-',
    serialNumber: 'Serial',
    address: 'Address',
    amount: 'Amount',
    time: 'Time',
    height: 'Height',
    voteType: 'Vote Type',
    tranType: 'Transaction Type',
    datavote: 'Vote',
    datawithdrawal: 'Withdrawal',
    ordinary: 'Ordinary',
    recasting: 'Recasting'
  },
  Index: {
    h2: 'Blockchain browser is the most accurate and fast data browser in the world. Users can use the blockchain browser to build wallets and query and search all blockchain data information on the chain ',
  },
  Pending: {
    h1: 'The browser queries the transactions to be confirmed on the chain, the number of transactions to be confirmed and the Force Fee ',
    h2: 'The browser can query the transactions to be confirmed, including the number of transactions, transaction volume, token transactions, total contract transactions, etc. ',
    tx: 'Transaction',
    hash: 'Hash',
    time: 'Time',
    amount: 'Amount',
    from: 'From',
    to: 'To',
  },
  Rank: {
    h1: 'Browser query blockchain and token rich list',
    h2: 'The browser displays the rich list, including the rich ranking, rich address and transaction amount.',
    rich: 'Accounts',
    rank: 'Ranking',
    address: 'Address',
    balance: 'Balance',
    yield: 'Yield',
    notRank: 'No ranking'
  },
  Tx: {
    txInfo: 'Transaction Infomation',
    block: 'Block',
    time: 'Time',
    from: 'From',
    to: 'To',
    txAmount: 'Transaction Amount',
    txFee: 'Force Fee',
    dpos_in: 'Vote Node Address',
    dpos_out: 'Withdrawal Node Address',
    client_in: 'Vote customer Address',
    client_out: 'Withdrawal Customer Address',
    nodeName: 'Node Name',
    voteAmount: 'Vote Amount',
    nodeDetails: 'Node Details',
    nodeAddress: 'Node Address',
    blockDeplete: 'Block Deplete',
    effectiveGasPrice: 'Actual GAS price',
    effectiveGasFee: 'Actual cost consumed',
    gasUsed: 'The actual GAS consumed',
    gasTv: 'Pay Rate GAS'
  },
  el: {
    pagination: {
      goto: 'goto',
      pagesize: ' pcs/page',
      total: 'total {total}',
      pageClassifier: 'page'
    }
  },
  routes: {
    browser: 'Blockchain Browser',
    home: 'Blockchain Browser-Home',
    blockList: 'Blockchain browser-Block list',
    txList: 'Blockchain browser-Transaction',
    block: 'Blockchain browser-Block',
    address: 'Blockchain browser-Address',
    tx: 'Blockchain browser-Transaction information',
    rank: 'Blockchain browser-Ranking',
    dpos: 'Blockchain browser-dpos',
    dposDetail: 'Blockchain browser-dposDetail',
    main: 'Blockchain browser-main',
  },
  hrc20: {
    hrc20: 'HRC20',
    owner: 'Owner',
    name: 'Name',
    symbol: 'Symbol',
    decimals: 'Decimals',
    totalSupply: 'totalSupply',
    overview: 'Overview',
    profileSummary: 'Profile Summary',
    quantity: 'Quantity',
    method: 'Method'
  },
  footBar: {
    title: 'Powered by Hash Ahead',
    company: 'Company',
    aboutUs: 'About Us',
    Product: 'Pruduct & Services',
    vote: 'HAH Vote',
    faucet: 'HAH Faucet',
    browser: 'HAH Browser',
  },
  pagination: {
    show: 'Per page:',
    records: 'Records',
    first: 'First',
    last: 'Last'
  },
  faucetSmart: {
    title: 'HAH Smart Chain Faucet',
    button: 'Give me HAH',
    errAddress: 'Please enter the correct address',
    released: '1 HAH has been issued to this address',
    repeat: 'Duplicate applications cannot be made',
    othererr: 'System error, please try again'
  },
  moduleTitle: {
    totalBlocks: 'Total of {count} blocks',
    totalTrade: 'More than {count} transactions found',
    totalRanks: 'Total of {count} address',
    totalNode: 'Total of {count} DPOS node',
    totalContract: 'Total of {count} HRC20',
    totalData: 'Total of {count} Data'
  },
  messageTips: {
    copySuccess: 'Copy Successful',
    fail: 'Copy failed, please re-replicate',
    noMore: 'No more data'
  },
  status: {
    success: 'Success',
    fail: 'Fail'
  },
  logs: {
    title: 'Logs',
    count: 'Transaction Receipt Event Logs({count})',
    address: 'Address',
    topics: 'Topics',
    data: 'Data',
    executionStatus: 'Execution Status',
    internalTransfers: 'HRC20 Transfers'
  },
  tradeDetails: {
    height: 'Block Height',
    hash: 'Transaction HASH',
    GASLimit: 'GAS limit',
    tradeType: 'Trade Type',
    GASPrice: 'GAS Price',
    contractCreate: 'Contract Creation',
    contractInvoke: 'Contract Call',
    genesis: 'Creation',
    stake: 'POS Mining',
    token: 'Ordinary',
    voteReward: 'Earnings',
    work: 'POA Mining'
  },
  overview: {
    out: 'Output Amount',
    trade: 'Number of transactions'
  },
  loadStatus: {
    loading: 'Loading...',
    notData: 'No data',
    error: 'Network error, click here to reload'
  },
  branch: {
    infor: 'Cryptex & Codex Chain',
    currentBranch: 'Current Branch',
    fork: 'Chain HASH',
    chainid: 'Chain ID',
    name: 'Chain Name',
    symbol: 'Chain Symbol',
    amount: 'number of starters',
    reward: 'Block Reward',
    halvecycle: 'Decay Period',
    owner: 'Creator Address',
    createtxid: 'Create Transaction HASH',
    createforkheight: 'Height at Creation',
    parentfork: 'Parent Chain HASH',
    forkheight: 'Current Height',
    lastnumber: 'Number of Blocks',
    lastblock: 'last block HASH',
    totaltxcount: 'total number of transactions',
    moneysupply: 'total issuance',
    moneydestroy: 'total amount destroyed',
    rewardtxcount: 'Number of reward transactions',
    usertxcount: 'User Transactions',
    viewAll: 'View all',
    operating: 'Operation',
    switch: 'Switch to this chain',
    choose: "Select branch"
  }
}